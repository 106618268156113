<template>
  <b-container fluid>
    <bo-page-title />    
    <div class="user">
    
      <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col>
              <b-form-row>
                <b-col md="4">
                  <date-picker placeholder="Filter By Date" value-type="format" format="YYYY-MM-DD" range prefix-class="crm" v-model="dateFilter" @input="doFilterDate" v-b-tooltip.hover="'Filter by Date'" />
                </b-col>
              </b-form-row>
            </b-col>
            <b-col cols="auto">
              <SearchInput :value.sync="filter.search" @search="doFilter" />
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <b-table :fields="fields" :items="dataList || []" :primary-key="idKey" :busy="!dataList" responsive show-empty striped>
            <template #empty>
              <div class="text-center">
                <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            <template #cell(aa_created_by)="data">
              {{data.value.name}}
            </template>
            <template #cell(aa_created_date)="data">
              <template v-if="data.value">
                {{data.value | moment("YYYY-MM-DD HH:mm:ss")}}
              </template>
            </template>
            <template #cell(action)="data">
              <b-button
                class="btn-icon"
                variant="outline-success"
                v-b-tooltip.hover="'Detail'"
                @click="detailSurvey(data.item)"
              ><i class="fas fa-eye"></i>
              </b-button>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom">        
            <b-pagination
              class="mb-0"
              v-if="data.data.length !== 0"
              v-model="pageNo"
              :per-page="data.per_page"
              :total-rows="data.total"
            />
        </b-card-footer>

      </b-card>
    </div>

    <b-modal
      id="detailAudit"
      title="Detail Audit Trail"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-col md=12>
          <b-form-group label="Full Name" label-for="cusName" label-class="mb-1">
            <span>{{ (dataModal.aa_created_by || {name: ''}).name }}</span>
          </b-form-group>
          <b-form-group label="Activity" label-for="cusName" label-class="mb-1">
            <span>{{ dataModal.aa_title }}</span>
          </b-form-group>
          <b-form-group label="Date" label-for="cusName" label-class="mb-1">
            <span v-if="dataModal.aa_created_date">{{ dataModal.aa_created_date | moment('LLL') }}</span>
          </b-form-group>
          <b-form-group label="Description" label-for="cusName" label-class="mb-1">
            <span>{{ dataModal.aa_description }}</span>
          </b-form-group>
          <b-form-group label="User Agent" label-for="cusName" label-class="mb-1">
            <span>{{ dataModal.aa_user_agent }}</span>
          </b-form-group>
          <b-form-group label="IP" label-for="cusName" label-class="mb-1">
            <span>{{ dataModal.aa_ip }}</span>
          </b-form-group>
        </b-col>
      </b-row>
       <template #modal-footer>
        <b-button size="md" variant="info" @click="() => $bvModal.hide('detailAudit')">Close</b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
  import GlobalVue from '@/libs/Global.vue'

  export default {
    extends: GlobalVue,
    data() {
      return {
        dateFilter: [],
        idKey: 'aa_id',
        rows: 30,
        dataOpt : [
          {value : "1", label: 'tes 1'},
          {value : "2", label: 'tes 2'},
          {value : "3", label: 'tes 3'},
          {value : "4", label: 'tes 4'}
        ],
        valueInp : "1",
        fields: [
          {
            key: 'number',
            label: '#',
          },
          {
            key: 'aa_created_by',
            label: 'Full Name',
          },
          
          {
            key: 'aa_title', 
            label: 'Activity'
          },
          {
            key: 'aa_created_date',
            label: 'Date',
          },
          {
            key: 'action',
          },
        ],
        dataModal: {},
      }
    },
    methods: {
      doFilterDate(newVal){
        this.$set(this.filter, 'start_date', newVal[0] || '')
        this.$set(this.filter, 'end_date', newVal[1] || '')
        this.doFilter()
      },
      notBeforeToday(date) {
        return date ?  false : false
        // return date < new Date(new Date().setHours(0, 0, 0, 0))
      },
      detailSurvey(data){
        this.$set(this, 'dataModal', data)

        this.$bvModal.show('detailAudit')
      },
      dateDisabled(ymd, date) {
        let startDate = null
        if(this.filter.startDate){
          startDate = this.filter.startDate.split("-")
          const day = date.getDate()
          const month = date.getMonth() + 1
          const year = date.getFullYear()

          if(year <= parseInt(startDate[0])){
            return day < parseInt(startDate[2]) || month < parseInt(startDate[1])
          }
          else{
            return
          }
        }
        return true
      }
    },
    mounted() {
      this.apiGet()
      const a = []
      a[0] = this.$route.query['start_date'] || null
      a[1] = this.$route.query['end_date'] || null

      this.$set(this, 'dateFilter', a)
    },
    watch:{
      $route(){
        this.apiGet()
      },
      '$route.params.pageSlug':function(){
        this.$set(this,'filter',{})
      },
      'filter'(){
        const a = []
        a[0] = this.filter['start_date'] || null
        a[1] = this.filter['end_date'] || null
        this.$set(this, 'dateFilter', a)
      },
    }
  }
</script>